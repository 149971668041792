import { APP_BASE_HREF, PathLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthModule } from './@auth/auth.module';
import { ImanagerComponent } from './pages/imanager/imanager.component';
import { NbToastrModule } from '@nebular/theme';
import { FileService } from './file-explorer/service/file.service';
import { FileExplorerModule } from './file-explorer/file-explorer.module';

import { UserActivityData } from './pages/common/user-activity';
import { UserActivityService } from './pages/common/user-activity.service';
import { StatesCitiesService } from './pages/common/statescities.service';

import { LoginComponent } from './@theme/components/login/login.component';

@NgModule({
  declarations: [AppComponent, ImanagerComponent, LoginComponent,
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    AuthModule.forRoot(),
    CoreModule.forRoot(),
    FileExplorerModule,
    NbToastrModule.forRoot(),
  ],
  entryComponents: [ImanagerComponent],
  bootstrap: [AppComponent],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: UserActivityData, useClass: UserActivityService},
    // { provide: APP_BASE_HREF, useValue: '/' },
    FileService,
    StatesCitiesService,
  ],
})
export class AppModule {
}
