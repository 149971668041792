export const environment = {
  production: false,
  apiProjectUrl: 'https://develop-nhh-harpers-cov-wave-projects-services-tpaiwcfcta-uc.a.run.app/projects',
  apiUrlAdmin: 'https://develop-nhh-harpers-cov-wave-admin-users-services-tpaiwcfcta-uc.a.run.app/admin-users',
  apiGoogleStorage: 'https://develop-nhh-harpers-cov-wave-google-storage-servi-tpaiwcfcta-uc.a.run.app/storage',
  urlStorage: "https://storage.googleapis.com/avwave-nhh-harpers-dev/",
  testUser: {
    // tslint:disable-line
    token: 'cualquiercosa',
    email: 'user@user.user',
  },
};
